const App = {
  SetBackground: () => {
    $("[setBackground]").each(function () {
      var background = $(this).attr("setBackground");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-size": "cover",
        "background-position": "center center",
      });
    });
    $("[setBackgroundRepeat]").each(function () {
      var background = $(this).attr("setBackgroundRepeat");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-repeat": "repeat",
      });
    });
  },
  EqualHeightElement: (el) => {
    let height = 0;
    let thisHeight = 0;
    $(el).each(function () {
      thisHeight = $(this).height();
      if (thisHeight > height) {
        height = thisHeight;
      }
    });
    $(el).height(height);
  },
  ScrollTo: (y) => {
    $("html, body").animate(
      {
        scrollTop: y,
      },
      1000
    );
  },
  Init: () => {
    App.ScrollTo();
    App.SetBackground();
  },
};

function InitSlider() {
  var heroBanner = new Swiper(".home-section-1 .swiper-container", {
    speed: 1600,
    slidesPerView: 1,
    spaceBetween: 15,
    // effect: "fade",
    // fadeEffect: {
    //   crossFade: true,
    // },
    navigation: {
      clickable: !0,
      nextEl: ".home-section-1 .swiper-next",
      prevEl: ".home-section-1 .swiper-prev",
    },
    pagination: {
      el: ".home-section-1 .swiper-pagination",
      clickable: !0,
      type: "bullets",
    },
  });
  var servicesSlide = new Swiper(".home-services .swiper-container", {
    speed: 1e3,
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
      992: {
        spaceBetween: 20,
      },
    },
    pagination: {
      el: ".home-services .swiper-pagination",
      clickable: !0,
      type: "bullets",
    },
    navigation: {
      clickable: !0,
      nextEl: ".home-services .swiper-next",
      prevEl: ".home-services .swiper-prev",
    },
  });
  if ($(".products-detail").length) {
    var galleryThumbs = new Swiper(".gallery-thumbs", {
      spaceBetween: 20,
      slidesPerView: 5,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });
    var galleryTop = new Swiper(".gallery-top", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });
  }
  $(".slick-container").slick({
    rows: 2,
    infinite: false,
    dots: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 5,
    prevArrow: $(".brand-slide .swiper-prev"),
    nextArrow: $(".brand-slide .swiper-next"),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
    ],
  });
}

function Headers() {
  $(document).on("click", ".toggle-btn", function () {
    $(this).toggleClass("active");
    $(".header").toggleClass("show");
    $(".header .mobile-nav-inner").toggleClass("active");
    $(".back-drop").toggleClass("active");
  });
  $(document).on("click", ".close-btn", function () {
    $(".cart-panel").removeClass("show");
    $(".cart-overlay").removeClass("active");
  });
  $(document).on("click", ".cart-toggle", function () {
    $(".cart-panel").toggleClass("show");
    $(".cart-overlay").addClass("active");
  });
  $(document).on("click", ".mb-filter", function () {
    $(".sidebar").slideToggle();
  });
  $(document).on("click", ".mobile-nav-inner li.has-dropdown>a", function () {
    $(this).toggleClass("active").next().slideToggle();
  });
  $(window).on("resize", function () {
    $("header .mobile-nav-inner").removeClass("active");
    $("body").removeClass("over-hidden");
    $(".back-drop,.cart-overlay").removeClass("active");
    $(".cart-panel").removeClass("show");
    $(".toggle-btn").removeClass("active");
  });
  $(document).on("click", ".back-drop,.cart-overlay", function () {
    $(this).removeClass("active");
    $(".header .mobile-nav-inner").removeClass("active");
    $(".search-wrapper").removeClass("active");
    $(".toggle-btn").removeClass("active");
    $(".cart-panel").removeClass("show");
  });
}
//mapping
function mappings() {
  var moveHeaderTop = new MappingListener({
    selector: "header .header-top",
    mobileWrapper: ".mobile-nav-inner",
    mobileMethod: "appendTo",
    desktopWrapper: "header",
    desktopMethod: "prependTo",
    breakpoint: 1200,
  }).watch();
  var moveNav = new MappingListener({
    selector: "header .menu",
    mobileWrapper: ".mobile-nav-inner",
    mobileMethod: "appendTo",
    desktopWrapper: "header .right-wrap",
    desktopMethod: "prependTo",
    breakpoint: 1200,
  }).watch();
}
function accordion() {
  $(".accordion-item.active").find(".accordion-content").show();
  $(".accordion-heading").on("click", function () {
    if (!$(this).parent().is(".active")) {
      $(this)
        .parent()
        .toggleClass("active")
        .children(".accordion-content")
        .slideToggle(500)
        .parent()
        .siblings(".active")
        .removeClass("active")
        .children(".accordion-content")
        .slideToggle(500);
    } else {
      $(this).parent().toggleClass("active");
      $(this).next().slideToggle(500);
    }
  });
}
function adjustProductAmount() {
  $(".plus").click(function () {
    if ($(this).parents(".amount").find(".input").val() < 10) {
      $(this)
        .parents(".amount")
        .find(".input")
        .val(+$(this).parents(".amount").find(".input").val() + 1);
    }
  });
  $(".minus").click(function () {
    if ($(this).parents(".amount").find(".input").val() > 1) {
      if ($(this).parents(".amount").find(".input").val() > 1)
        $(this)
          .parents(".amount")
          .find(".input")
          .val(+$(this).parents(".amount").find(".input").val() - 1);
    }
  });
}
function jarallax() {
  $(".jarallax").jarallax({
    speed: 0.2,
  });
}
function myTabs() {
  $(".my-tabs").each(function () {
    var $this = $(this);
    $this.find(".tab-content:not(:first)").toggle();
    $this.find(".tabs-nav li:first").addClass("active");
    // $(".previous").hide();
    $this.find(".tabs-nav li").click(function () {
      // if ($(this).is(":last-child")) {
      //   $(".next").hide();
      // } else {
      //   $(".next").show();
      // }
      // if ($(this).is(":first-child")) {
      //   $(".previous").hide();
      // } else {
      //   $(".previous").show();
      // }
      var position = $(this).position();
      var corresponding = $(this).data("tab");
      scroll = $(".tabs-nav").scrollLeft();
      $(".tabs-nav").animate(
        {
          scrollLeft: scroll + position.left,
        },
        200
      );
      $(".tabs-container .tab-content").hide();
      $(".tab-content." + corresponding).toggle();
      $(".tabs-nav li").removeClass("active");
      $(this).addClass("active");
    });
    $(".nav-wrap .next").click(function (e) {
      e.preventDefault();
      $(this)
        .parents(".nav-wrap")
        .find("li.active")
        .next("li")
        .trigger("click");
    });
    $(".nav-wrap .prev").click(function (e) {
      e.preventDefault();
      $(this)
        .parents(".nav-wrap")
        .find("li.active")
        .prev("li")
        .trigger("click");
    });
  });
}
function checkboxForm() {
  $(".another-address .checkbox-custom #form-2").click(function () {
    if ($(this).is(":checked")) {
      $(this).parents(".another-address").find(".form-2").show();
    } else {
      $(this).parents(".another-address").find(".form-2").hide();
    }
  });
}
function fixedNav() {
  var nav = $(".header-bottom");
  if (nav.length) {
    var offsetTop = nav.offset().top,
      headerHeight = nav.height();

    $(window).on("load scroll", function () {
      if ($(window).scrollTop() > offsetTop) {
        nav.addClass("fixed-hide");
      } else {
        nav.removeClass("fixed-hide");
      }
      if ($(window).scrollTop() > 300) {
        nav.addClass("fixed-show");
      } else {
        nav.removeClass("fixed-show");
      }
    });
  }
}
function selectUi() {
  $(".custom-select__list").on(
    "click",
    ".custom-select__item.init",
    function () {
      if ($(this).parents(".custom-select").hasClass("open")) {
        $(this).parents(".custom-select").removeClass("open");
        $(this).parent().children(".custom-select__item:not(.init)").hide();
      } else {
        $(".custom-select").removeClass("open");
        $(this).parents(".custom-select").addClass("open");
        $(".custom-select__item:not(.init)").hide();
        $(this).parent().children(".custom-select__item:not(.init)").show();
      }
    }
  );
  var allOptions = $(".custom-select__list").children(
    ".custom-select__item:not(.init)"
  );
  $(".custom-select__list").on(
    "click",
    ".custom-select__item:not(.init)",
    function () {
      allOptions.removeClass("selected");
      $(this).addClass("selected");
      $(this)
        .parents(".custom-select__list")
        .children(".custom-select__item.init")
        .html($(this).html())
        .css("color", "#000");
      $(this)
        .parents(".custom-select__list")
        .children(".custom-select__item:not(.init)")
        .toggle();
      $(this).parents(".custom-select").toggleClass("open");
      $("input#inputInit").val($(this).data("val"));
    }
  );
  var $menu = $(".custom-select");

  $(document).mouseup(function (e) {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
      $menu.removeClass("open");
      $menu.find(".custom-select__item:not(.init)").hide();
    }
  });
}
function productOffset() {
  var headerHeight = $(".header").height();
  $(".product .tabs-nav li").on("click", function () {
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop:
            $(".product .product-categories .tabs-container").offset().top -
            headerHeight,
        },
        500,
        "linear"
      );
  });
}

function sidebarDropdown() {
  //mega menu
  $(document).on("click", ".sidebar .sidebar-text", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).siblings(".menu-sub").slideUp(200);
    } else {
      $(".sidebar .sidebar-text").removeClass("active");
      $(this).addClass("active");
      $(".menu-sub").slideUp(200);
      $(this).siblings(".menu-sub").slideDown(200);
    }
  });
}
function checkWidth() {
  var $window = $(window);
  var windowsize = $window.width();
  if (windowsize >= 768) {
    $(".sidebar").removeAttr("style");
  }
}
function aosAnimation() {
  $(".page-head").each(function () {
    $(this).find(".main-title").attr({
      "data-aos": "fade-up",
    });
    $(this).find(".subtitle").attr({
      "data-aos": "fade-up",
      "data-aos-delay": "150",
    });
    $(this).find(".page-title").attr({
      "data-aos": "fade-up",
      "data-aos-delay": "300",
    });
    $(this).find(".desc").attr({
      "data-aos": "fade-up",
      "data-aos-delay": "450",
    });
  });
  $(".tag-head").each(function () {
    $(this).find(".subtitle").attr({
      "data-aos": "fade-up",
    });
    $(this).find(".title").attr({
      "data-aos": "fade-up",
      "data-aos-delay": "150",
    });
    $(this).find(".desc").attr({
      "data-aos": "fade-up",
      "data-aos-delay": "300",
    });
    $(this).find(".btn-wrap").attr({
      "data-aos": "fade-up",
      "data-aos-delay": "450",
    });
  });
  $(".services-section").each(function () {
    $(this).find("article:nth-child(odd) .box-img").attr({
      "data-aos": "box",
      "data-aos-delay": "500",
    });
    $(this).find("article:nth-child(even) .box-img").attr({
      "data-aos": "box-reverse",
      "data-aos-delay": "500",
    });
  });
}
$(window).resize(checkWidth).trigger("resize");
$(document).ready(function () {
  App.Init();
  InitSlider();
  aosAnimation();
  Headers();
  fixedNav();
  mappings();
  adjustProductAmount();
  accordion();
  selectUi();
  sidebarDropdown();
  jarallax();
  myTabs();
  productOffset();
  checkboxForm();
  checkWidth();
  AOS.init({
    once: true,
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
  });
});
